/* .image1 {
  position: relative;
  top: 0;
  left: 0;
    height: 5% !important;
    background: rgba(32, 5, 78, 0.7);
     filter: brightness(10%);
     opacity: 0.5;
      z-index: 0;
} */
.cover {
  /* background-image: url('ComponentTMP_0-image.png'); */
  background-image:
  /* linear-gradient(to right, #789f73, transparent ,  #fff , transparent 100%), */
  /* linear-gradient(to left , #624e80 ,transparent, #fff , transparent 100%), */
  /* linear-gradient(to right, #789f73 ,transparent 100%, #624e80,transparent 100% ), */ 
  linear-gradient(
      to right,rgba(120, 159, 115, 0.75), rgba(98, 78, 128, 0.60)),
    url("ComponentTMP_0-image.png");
  /* url(
        'data:image/svg,<svg class="p4_l"><linearGradient id="p4_l" spreadMethod="pad" x1="0" x2="1.019" y1="0.523" y2="0.5"> <stop offset="0" stop-color="#789f73" stop-opacity="1"></stop> <stop offset="1" stop-color="#624e80" stop-opacity="0.702"></stop></linearGradient> <rect id="p4_l" rx="0" ry="0" x="0" y="0" width="1440" height="439"></rect></svg>'
      ); */
  background-size: cover;
  /* background-color: transparent; */
  height: 439px !important;
  /* transition: opacity 0.83s; */
  /* opacity: 0.84; */
  fill: url(#p4_l);
  /* opacity: 1.5; */
}
#p4_l {
  opacity: 0.84;
  fill: url(#p4_l);
}
.p4_l {
  position: absolute;
  overflow: visible;
  width: 1440px;
  height: 439px;
  left: 0px;
  top: 0px;
}
.image1 {
  /* position: relative; */
  /* top: 0; */
  /* left: 0; */
  /* color:'#172b4d' ; */
  /* background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgb(49, 129, 5)), color-stop(50%,rgb(11, 185, 216))); */
  /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%); */

  /* width: 1440; */
  background-size: cover;
  height: 439px !important;
  /* background: rgba(32, 5, 78, 0.7); */
  /* filter: brightness(10%); */
  opacity: 0.84;
  /* transform: scale(2); */
  /* line-height:1.5; */
  /* padding: 50px; */
  overflow: visible;
  /* z-index: 0; */
}
.image1Parent {
  mask-image: linear-gradient(to left, rgb(49, 129, 5) 10%, transparent 150%);
  /* mask-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgb(49, 129, 5)), color-stop(50%,rgb(11, 185, 216))); */
}

.image2 {
  position: absolute;
  top: 10%;
  left: 30%;
}

.mobile {
  margin-top: 2%;
  position: relative;
  left: 15%;
  background-color: #543490 !important;
  width: 30%;
  color: white;
  border-radius: 1%;
  padding: 1%;
  display: inline-block;
}
.email {
  display: inline-block;
  position: relative;
  left: 19%;
  background-color: #e67605 !important;
  width: 30%;
  color: white;
  border-radius: 1%;
  padding: 1%;
}
.address {
  position: relative;
  margin-top: 2%;
  background-color: #01d277 !important;
  padding: 1.5%;
  color: white;
  width: 64.2%;
  left: 15%;
}

iframe {
  border: 0px;
  margin: "auto";
}
.map {
  /* position: absolute; */
  margin-top: 5%;
  /* left: 15%; */
  width: 70%;
  /* align-items: "center" ; */
  /* margin: "auto" !important; */
}
.about {
  position: relative;
  margin-top: 40%;
  margin-left: 15%;
}
.person {
  position: relative;
  max-height: 350px;
  left: 55%;
  top: -10%;
  margin-top: -15%;
}
.aboutText {
  width: 50%;
}
.footerimg {
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  filter: grayscale(100%);
  height: 10px;
}
.footer {
  height: 5%;
}
.areyoumember {
  position: relative;
  margin-top: -20%;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
  font-family: "Nunito", sans-serif;
  margin-left: 17%;
}
.font-weight-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 4rem;
}
.ifmember {
  font-size: 1.3em;
  margin-left: 17%;
}
.footer-div {
  position: relative;
  background-color: indigo;
  margin-top: 27%;
  color: white;
}
.fdiv {
  padding: 1%;
  margin-left: 10%;
}
.loginbtn {
  color: aliceblue;
  margin: 10px;
  /* margin-top: 5px; */
}
.overlay {
  /*background: rgba(32, 5, 78, 0.7);*/
  /*z-index: 2;*/
  /*cursor: pointer;*/
  /*margin: 10px;*/
  /*margin-right: 10;*/
}
