

.social{
    margin-top:2%;
    width: max-content !important;
}

.contactInfo{
    margin-top:-2%;
}
.contactText{
    margin-left:-6%;
    margin-top: -1%;
}
.socialIcon{
    color:green ;
    margin-right: 1;
    margin-top:10;
    transition: '0.4s';
}
.footerLink {
    width: max-content !important;
}

.socialIcon:hover{
    color:#789f73 ;

    /* margin-right: 1; */
}
.whatsAppIcon{
    color:white ;
    background-color: green;
    border-radius: 20px;
    transition: 0.4;
    width: 50px;
    height: 50px;
    /* width: 100%; */
    /* height:100% ; */
    padding: 3px;
    /* padding-bottom: -1px; */
}
.whatsAppIcon:hover{
    opacity:0.8;
    background-color: green;

    /* background-color: #789f73; */

    /* color:#789f73 ; */

    /* margin-right: 1; */
}